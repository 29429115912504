<template>
  <div>
    <el-tree :load="loadNode" lazy ref="tree" :data="data" :props="props" @node-click="handleNodeClick" node-key="id" :default-expanded-keys="defaultExpanded" :current-node-key="currentNodekey" :highlight-current="true">
      <span slot-scope="{ node, data }">
        <el-radio :title="node.label" v-model="radiozy" class="show-ellipsis" :disabled="flag&&node.data.id== defaultExpanded" :label="data.id">
          {{ node.label }}
        </el-radio>
      </span>
    </el-tree>
  </div>
</template>

<script>
export default {
  props: {
    flag: {
      type: Boolean,
      default: true
    },
    trees: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      userId:"",
      defaultExpanded: [sessionStorage.getItem('manageGroupId')],
      radiozy: sessionStorage.getItem('manageGroupId'),
      data: [],
      currentNodekey: sessionStorage.getItem('manageGroupId'),
      props: {
        //组织树props 提前告知子节点是否还有加载 isLeaf
        label: 'name',
        children: 'data',
        isLeaf: 'isParent'
      }
    }
  },
  created() {
    this.handleNodeClick({ id: sessionStorage.getItem('manageGroupId') })
    if (this.flag) {
      this.radiozy = ''
    }
  },
  methods: {
    //点击tree节点事件
    handleNodeClick(node, data, value) {
      const strId = sessionStorage.getItem('manageGroupId')
      this.$nextTick(()=>{
        this.radiozy = node.id
      })
      this.$emit('radioTree', node.id, data?data.data:'')
    },
    // 树列表
    async loadNode(node, resolve) {
      const str = sessionStorage.getItem('manageGroupFullName')
      var root_name = str.substring(str.lastIndexOf('•') + 1)
      const id = sessionStorage.getItem('manageGroupId')
      if (node.level === 0) {
        return resolve([
          {
            name: root_name,
            id: id
          }
        ])
      }
      let token2 = sessionStorage.getItem("Authorization") ?sessionStorage.getItem("Authorization"):
        'eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE2MzMwMjMzNzUsInVzZXJJZCI6MSwiaWF0IjoxNjMzMDAxNzc1fQ.hSF4bNDHVFZR2h4R0osNBF56ChR_l03mwx9QYaS1E0JDtIFlme3dP8oktCjEIaGi4QzXutLRMmjFWxuDNtaMMA'
      console.log(token2,'token2')
      let userString = decodeURIComponent(
        escape(window.atob(token2.split('.')[1]))
      )
      console.log(userString,'userString截取') 
      this.userId = JSON.parse(userString).userId
      var param = {
        parentId: node.data ? node.data.id : this.treeParentId,
        userId: this.userId
      }
      const res = await this.$axios.post(
        '/httpServe/tree/selectNode',
        param,
        true
      )
      res.data.content.forEach((item, index) => {
        if (item.isParent == 'true') {
          item.isParent = false
        } else {
          item.isParent = true
        }
      })
      resolve(res.data.content)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
