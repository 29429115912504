<template>
  <div>
    <el-tree
      :load="loadNode"
      lazy
      ref="tree"
      :data="data"
      :props="props"
      @node-click="handleNodeClick"
      node-key="id"
      :default-expanded-keys="defaultExpanded"
      :current-node-key="currentNodekey"
      :highlight-current="true"
    >
    
      <span slot-scope="{ node, data }"> 
        <el-radio
          :title="node.label"
          v-model="radiozy"
          class="show-ellipsis"
          :disabled="node.data.id== disabledId"
          :label="data.id"
        >
          {{ node.label }}
        </el-radio>
      </span>
    </el-tree>
  </div>
</template>

<script>
export default {
  props: {
    flag: {
       type: Boolean,
      default: false, 
    },
    trees: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      disabledId: sessionStorage.getItem('manageGroupId'),
      userId:"",
      // radiozy:1,
      radiozy: sessionStorage.getItem('manageGroupId'),
      data: [],
     defaultExpanded: [sessionStorage.getItem('manageGroupId')],
      currentNodekey: sessionStorage.getItem('manageGroupId'),
      props: {
        //组织树props 提前告知子节点是否还有加载 isLeaf
        label: "name",
        children: "data",
        isLeaf: "isParent",
      },
    };
  },
  created() { 
    this.handleNodeClick({ id: sessionStorage.getItem('manageGroupId') });//{ id: '1' }
    if(this.flag) {
        this.radiozy = ""
    }
  },
  methods: {
    //点击tree节点事件
    handleNodeClick(node, data, value) { 
      if(data){
        if (data?.data.id !== sessionStorage.getItem('manageGroupId') ) {
          this.radiozy = node.id;
        }else{
          this.radiozy = ''
        }
        
        this.$emit("radioTree", this.radiozy);
      }
      
    },
    // 树列表
    async loadNode(node, resolve) {
    //   if (node.level === 0) {
    //     return resolve([{ name: "EMM", id: 1 }]);
    //   }
    let token2 = sessionStorage.getItem("Authorization") ?sessionStorage.getItem("Authorization"):
        'eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE2MzMwMjMzNzUsInVzZXJJZCI6MSwiaWF0IjoxNjMzMDAxNzc1fQ.hSF4bNDHVFZR2h4R0osNBF56ChR_l03mwx9QYaS1E0JDtIFlme3dP8oktCjEIaGi4QzXutLRMmjFWxuDNtaMMA'
      
      let userString = decodeURIComponent(
        escape(window.atob(token2.split('.')[1]))
      )
  
      this.userId = JSON.parse(userString).userId
      var param = {
        parentId: node.data ? node.data.id : '',
        userId: this.userId
      };
      const res = await this.$axios.post(
        "/httpServe/tree/selectNode",
        param,
        true
      );
      res.data.content.forEach((item, index) => {
        if (item.isParent == "true") {
          item.isParent = false;
        } else {
          item.isParent = true;
        }
      });
      resolve(res.data.content);
    }
  },
};
</script>

<style lang="scss" scoped>
</style>