<template>
  <div>
    <el-tree :load="loadNode" lazy ref="tree" :data="data" :props="props" @node-click="handleNodeClick" node-key="id" :default-expanded-keys="defaultExpanded" :current-node-key="currentNodekey" :highlight-current="true">
      <span slot-scope="{ node, data }">
        <el-radio :title="node.label" v-model="radiozy" class="show-ellipsis" :disabled="node.data.id == disabledId" :label="data.id">
          {{ node.label }}
        </el-radio>
      </span>
    </el-tree>
  </div>
</template>

<script>
export default {
  props: {
    trees: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      userId: '',
      disabledId: sessionStorage.getItem('manageGroupId'),
      radiozy: sessionStorage.getItem('manageGroupId'),
      data: [],
      defaultExpanded: [sessionStorage.getItem('manageGroupId')],
      currentNodekey: sessionStorage.getItem('manageGroupId'),
      props: {
        //组织树props 提前告知子节点是否还有加载 isLeaf
        label: 'name',
        children: 'data',
        isLeaf: 'isParent'
      }
    }
  },
  created() {
    this.handleNodeClick({ id: sessionStorage.getItem('manageGroupId') }) //{ id: '1' }
  },
  methods: {
    //点击tree节点事件
    handleNodeClick(node, data, value) {
      if (data) {
        console.log(data, 'data')
        if (data.data.id !== sessionStorage.getItem('manageGroupId')) {
          this.radiozy = node.id
        }else {
          this.radiozy = "";
        }
        this.$emit('radioTree', node.id, data.data)
      }
    },
    // 树列表
    async loadNode(node, resolve) {
      let token2 = sessionStorage.getItem('Authorization')
      let userString = decodeURIComponent(
        escape(window.atob(token2.split('.')[1]))
      )
      this.userId = JSON.parse(userString).userId
      var param = {
        parentId: node.data ? node.data.id : '',
        userId: this.userId
      }
      const res = await this.$axios.post(
        '/httpServe/tree/selectNode',
        param,
        true
      )
      res.data.content.forEach((item, index) => {
        if (item.isParent == 'true') {
          item.isParent = false
        } else {
          item.isParent = true
        }
      })
      resolve(res.data.content)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
