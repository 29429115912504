<template>
  <div class="transfer">
    <div class="zTreeSelect">
      <el-tree :data="data" node-key="id" ref="data" :props="defaultProps2" :expand-on-click-node="false" default-expand-all>
        <span slot-scope="{ node, data }">
          <el-radio v-model="radio" :label="data.id" @change="change_group2(data.id)">
            <i class="el-icon-folder-opened">{{ node.label }}</i>
          </el-radio>
        </span>
      </el-tree>
    </div>
    <div>
      如有同名文件：<el-radio v-model="radio1" label="1">覆盖</el-radio>
      <el-radio v-model="radio1" label="2">重命名</el-radio>
    </div>
    <div v-if="flagbtn" slot="footer" class="diailog-footer">
      <el-button v-if="addBtn" size="small" type="primary" class="btn-add" @click="sureBtn">转移</el-button>
      <el-button v-if="closeBtn" size="small" type="info" class="btn-close" @click="close">关闭</el-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      defaultProps2: {
        children: 'children',
        label: 'name'
      },
      data: [
        {
          id: 'd_0',
          name: '文档管理',
          children: []
        }
      ],
      radio: 'd_0',
      radio1: '',
      flagbtn: true, //是否显示转移按钮
      addBtn: true,
      closeBtn: true
    }
  },
  created() {},
  mounted() {
    // this.data.children = this.dictData;
    this.transfer()
  },
  methods: {
    change_group2(id) {},
    // 转移
    async transfer() {
      this.transferFlag = true
      var parma = { parentId: 0 }
      const res = await this.$axios.post(
        '/api/document/selectFile.do',
        parma,
        true
      )
      console.log(res, '转移')
      this.data[0].children = res
      console.log(this.data)
    },
    sureBtn() {},
    close() {}
  }
}
</script>

<style lang='scss' scoped>
.zTreeSelect {
  width: 260px;
  height: 250px;
  overflow-x: auto;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  padding-left: 6px;
}
.transfer {
  width: 100%;
  padding: 20px 55px 0 55px;
}
::v-deep .el-form-item {
  margin-bottom: 0px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>
