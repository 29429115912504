<template>
  <div>
    <!-- <navi-gation /> -->
    <!-- 组织结构，表格列表 -->
    <div v-if="bigflag == 1">
      <el-row
        :gutter="30"
        class="box"
        ref="box"
        style="padding: 5px 5px 5px 15px; margin-top: 10px"
      >
        <el-col :span="24" style="display: flex; justify-content: space-around">
          <div class="leftBox" ref="leftBox" style="display: flex">
            <div class="leftTree" style="max-width: 60%">
              <div class="wrap_tree" id="myDiv">
                <el-input
                  v-model="searchGroup"
                  size="mini"
                  type="text"
                  :placeholder="$t('UserManage.user.placeholder')"
                  style="width: 100%"
                  v-on:input="onSearch"
                  @input="inputChange"
                  @focus="focus"
                >
                  <i
                    @click="onSearch"
                    slot="suffix"
                    class="el-input__icon el-icon-search"
                    style="cursor: pointer"
                  ></i>
                </el-input>
                <!-- //模糊搜索弹框 -->
                <div v-if="searchGroup">
                  <ul
                    style="
                      overflow: hidden auto;
                      padding: 1px 0px;
                      box-shadow: 0px 0px 10px rgb(232, 232, 232);
                    "
                    class="treeBox"
                  >
                    <li
                      class="liHover"
                      style="
                        height: 30px;
                        line-height: 30px;
                        padding-left: 14px;
                        border: 1px solid #c6c6c6;
                        color: gray;
                        margin-top: -1px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: block;
                      "
                      :title="item.groupName"
                      v-for="(item, index) in searchResult"
                      :key="index"
                      @click="clickList(item)"
                    >
                      {{ item.groupName }}
                    </li>
                  </ul>
                </div>
                <div v-else class="treeBox">
                  <Tree @tree="trees" />
                </div>
              </div>
              <div class="dragPoint" style="right: -11px">
                <i>
                  <img src="../../../images/icon_drag.png" alt="" />
                </i>
              </div>
            </div>

            <!-- 表格列表 -->
            <div
              v-if="flag == 1"
              class="bigbox"
              ref="leftTable"
              style="width: calc(100% - 253px)"
            >
              <el-row :gutter="4" class="mt15" style="display: flex">
                <!-- 用户状态 style="width: 136px; margin-right: 5px" -->
                <el-col :span="2">
                  <el-select
                    size="small"
                    v-model="userTypes.useType"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="$t(`UserManage.user.UserStatus.${item.label}`)"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-col>
                <select-input
                  :options="options1"
                  @selectSearchChange="selectSearchChange"
                  @searchList="onSubmit"
                ></select-input>
              </el-row>
              <div class="buttonGroupDiv">
                <el-button-group>
                  <el-button
                    @click="addBtn"
                    :type="info4"
                    class="delBtn"
                    :disabled="flagStatus4"
                    size="small"
                    >{{ $t("public.New") }}
                  </el-button>
                  <!--  @click="transfer(trunData)" -->
                  <el-button
                    :type="info"
                    size="small"
                    class="delBtn"
                    :disabled="flagStatus"
                    @click="transfer"
                    >{{ $t("public.Transfer") }}
                  </el-button>
                  <el-button
                    @click="tubePeople(peopleData)"
                    :type="info1"
                    size="small"
                    class="delBtn"
                    :disabled="flagStatus1"
                    >{{ $t("UserManage.user.SetAsAdministrator") }}
                  </el-button>
                  <el-button
                    @click="TYbtn()"
                    :type="info2"
                    :disabled="flagStatus2"
                    size="small"
                    >{{ $t("public.Outofservice") }}</el-button
                  >
                  <el-button
                    @click="QYbtn()"
                    :type="info3"
                    :disabled="flagStatus3"
                    size="small"
                    >{{ $t("public.Enable") }}</el-button
                  >
                  <el-button
                    @click="unlockBtn(delarr)"
                    :type="infoUnlock"
                    :disabled="unlockFlag"
                    size="small"
                    >{{ $t("public.Unlock") }}</el-button
                  >
                  <el-button
                    @click="theInport()"
                    :type="info5"
                    :disabled="flagStatus5"
                    size="small"
                    >{{ $t("public.Toleadinto") }}</el-button
                  >
                  <el-button
                    @click="resetForm()"
                    :type="info6"
                    :disabled="flagStatus6"
                    size="small"
                    >{{ $t("public.Derive") }}</el-button
                  >
                  <el-button
                    @click="delMuch(delarr)"
                    :type="info"
                    size="small"
                    class="delBtn"
                    :disabled="flagStatus"
                    >{{ $t("public.Delete") }}
                  </el-button>
                </el-button-group>
              </div>
              <el-row :gutter="16" style="margin: 10px 0px 0px 10px">
                <el-table
                  :cell-style="{ padding: '3px 0px' }"
                  :header-row-style="{ height: '30px' }"
                  @select="delobj"
                  :row-key="getRowKeys"
                  size="mini"
                  ref="multipleTables"
                  :data="tableData"
                  class="tableClass"
                  stripe
                  tooltip-effect="dark"
                  style="width: 100%; overflow: auto"
                  :default-sort="{
                    prop: 'u.updateTime',
                    order: 'descending',
                  }"
                  @sort-change="sort"
                  @selection-change="handleSelectionChange"
                >
                  <el-table-column
                    :reserve-selection="true"
                    :selectable="selectEnable"
                    header-align="center"
                    align="center"
                    type="selection"
                    width="60"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="u.loginName"
                    sortable="custom"
                    :show-overflow-tooltip="true"
                    :label="$t('public.Username')"
                    width="100"
                  >
                    <template slot-scope="scope">
                      <span>
                        {{ scope.row.loginName }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :show-overflow-tooltip="true"
                    prop="u.userName"
                    sortable="custom"
                    :label="$t('public.Name')"
                    width="100"
                  >
                    <template slot-scope="scope">
                      <span>
                        {{ scope.row.userName }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="u.userSex"
                    :label="$t('UserManage.user.UserList.sex')"
                    sortable="custom"
                    width="100"
                  >
                    <template v-slot="{ row }">
                      <template v-if="row.userSex == 0">
                        {{ $t("UserManage.user.UserList.male") }}
                      </template>
                      <template v-if="row.userSex == 1">
                        {{ $t("UserManage.user.UserList.female") }}
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="g.groupFullName"
                    sortable="custom"
                    :show-overflow-tooltip="true"
                    :label="$t('public.Section')"
                    width="100"
                  >
                    <template slot-scope="scope">
                      <span v-if="scope.row.groupFullName">
                        {{
                          scope.row.groupFullName.substr(
                            groupFullNameShowByIndex
                          )
                        }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="userGroupName"
                    sortable="custom"
                    :show-overflow-tooltip="true"
                    :label="$t('Delivery.DistributionHistory.UserGroup')"
                    width="100"
                  >
                    <template slot-scope="scope">
                      <span v-if="scope.row.userGroupName">
                        {{ scope.row.userGroupName }}
                      </span>
                      <span v-else>/</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="u.phone"
                    sortable="custom"
                    :label="$t('public.Phone')"
                    width="100"
                  >
                    <template slot-scope="scope">
                      <span>
                        {{ scope.row.phone | PhoneFilter }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="u.email"
                    sortable="custom"
                    :label="$t('public.Email')"
                    width="150"
                  >
                    <template slot-scope="scope">
                      <span>
                        {{ scope.row.email | hideMail }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="u.deviceCount"
                    sortable="custom"
                    :label="$t('public.AssociatedDevices')"
                    width="100"
                  >
                    <template v-slot="{ row }">
                      <span
                        @click="nameApp(row)"
                        v-if="row.deviceCount <= 0"
                        style="color: #606266"
                      >
                        {{ row.deviceCount }}
                      </span>
                      <span
                        @click="nameApp(row)"
                        v-else
                        style="color: #d38a08; cursor: pointer"
                      >
                        {{ row.deviceCount }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="u.status"
                    sortable="custom"
                    :label="$t('UserManage.user.UserList.UserStatus')"
                    width="100"
                  >
                    <template v-slot="{ row }">
                      <template v-if="row.status == 1">
                        <span style="color: #2ba245">{{
                          $t("public.Normal")
                        }}</span>
                      </template>
                      <template v-if="row.status == 2">
                        <span style="color: orange">{{
                          $t("public.Lock")
                        }}</span>
                      </template>
                      <template v-if="row.status == 3">
                        <span style="color: #ff0000">{{
                          $t("public.Outofservice")
                        }}</span>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="u.updateTime"
                    sortable="custom"
                    :show-overflow-tooltip="true"
                    :label="$t('public.ModificationTime')"
                    width="140"
                  >
                    <template slot-scope="scope">
                      <span v-if="scope.row.time == ''"></span
                      ><span class="detaildesc" v-else>
                        {{ scope.row.updateTime | dateformat }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('public.Controls')">
                    <template v-slot="{ row }">
                      <!-- reset_pwd -->
                      <span class="action_icon">
                        <img
                          :title="$t('UserManage.user.UserList.ResetPassword')"
                          v-show="row.id != 4"
                          @click="pasword(row)"
                          src="../../../assets/reset_pwd.png"
                          alt=""
                        />
                      </span>
                      <span class="action_icon" v-if="row.status == 2">
                        <img
                          :title="$t('public.Unlock')"
                          v-show="row.id != 4"
                          @click="unlockIncident(row)"
                          src="../../../assets/unlock.png"
                          alt=""
                        />
                      </span>
                      <span class="action_icon">
                        <img
                          :title="$t('public.Editor')"
                          v-show="row.id != 4"
                          @click="edit(row)"
                          src="../../../assets/icon_write.png"
                          alt=""
                        />
                      </span>
                      <span class="action_icon">
                        <img
                          :title="$t('public.Delete')"
                          v-show="row.id != 4"
                          @click="del(row.id)"
                          src="../../../assets/icon_del.png"
                          alt=""
                        />
                      </span>
                    </template>
                  </el-table-column>
                </el-table>
              </el-row>
              <el-row :gutter="16" style="margin: 10px 0px 0px 10px">
                <pagination
                  :page="queryData.currentPage"
                  :limit="queryData.pageSize"
                  :total="queryData.total"
                  :pageSizes="[10, 20, 30, 40]"
                  v-on:handleChildGetList="handleParentGetList"
                />
              </el-row>
            </div>
            <div v-if="flag == 2">
              <AddUser
                @addButton="addButtons"
                :addArray="addArray"
                :addArrayId="addArrayId"
              />
            </div>
            <div v-if="flag == 5">
              <TheImport @theImport="theImports" />
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 提示消息框 -->
    <dialogInfo
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
    <!-- 设置为瓜里源 -->
    <div v-if="bigflag == 2">
      <ManageUser
        @save="ManageSave"
        :addArray="qUse[0]"
        :addArrayId="addArrayId"
      />
    </div>
    <!-- 转移树形结构弹框 -->
    <Eldialog
      v-if="zhuanyi"
      :num="'470px'"
      :title="$t('UserManage.user.TransferPopUp.SelectDepartment')"
      :dialogVisible="zhuanyi"
      @handleClose="closeTree"
      :flagbtn="true"
      :btnTitle="$t('UserManage.user.TransferPopUp.Transfer')"
      :cancelBtn="true"
      :determineBtn="true"
      @determine="detezhuanyi"
    >
      <div class="device-tree">
        <el-scrollbar style="height: 100%">
          <ZhuanYi @radioTree="radioTree" />
        </el-scrollbar>
      </div>
    </Eldialog>
    <!-- 重置密码弹框 -->
    <Eldialog
      :title="$t('UserManage.user.popReset.ResetTitle')"
      :width="'405px'"
      :num="'144px'"
      :btnTitle="$t('UserManage.user.popReset.submit')"
      :dialogVisible="statusVisiblemm"
      @handleClose="handleClose"
      @heightCustom="heightCustom"
      :flagbtn="true"
      :determineBtn="true"
      @determine="determine"
    >
      <el-form
        style="height: 90px; margin-top: 10px"
        size="small"
        :model="form"
        :rules="rules1"
        ref="form"
        label-width="100px"
        class="demo-ruleForm restForm"
      >
        <el-form-item
          :label="$t('UserManage.user.popReset.NewPassword')"
          prop="newPassword1"
        >
          <el-input
            type="password"
            v-model="form.newPassword1"
            clearable
            size="mini"
            class="passinputs"
          ></el-input>
        </el-form-item>
        <el-form-item
          style="margin-top: 5px"
          :label="$t('UserManage.user.popReset.ConfirmPassword')"
          prop="newPassword2"
        >
          <el-input
            v-model="form.newPassword2"
            clearable
            type="password"
            size="mini"
            class="passinputs"
          ></el-input>
        </el-form-item>
      </el-form>
    </Eldialog>
    <!-- 关联应用的弹框 -->
    <Eldialog
      :title="$t('public.AssociatedDevices')"
      :num="'470px'"
      :dialogVisible="statusVisible"
      @handleClose="handleClose"
      @heightCustom="heightCustom"
    >
      <el-input
        v-model="ipt"
        @keyup.enter.native="updateData"
        size="small"
        style="width: 180px; margin-bottom: 10px"
        :placeholder="$t('UserManage.user.UserList.PleaseEnterTheDeviceName')"
        ><i
          @click="updateData"
          slot="suffix"
          class="el-input__icon el-icon-search"
          style="cursor: pointer"
        ></i
      ></el-input>
      <el-table
        :cell-style="{ padding: '3px 0px' }"
        :header-row-style="{ height: '30px' }"
        size="mini"
        stripe
        ref="multipleTable"
        :data="tableDataNmae"
        class="sort_table"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          :show-overflow-tooltip="true"
          prop="deviceName"
          :label="$t('public.DeviceName')"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="osId"
          :label="$t('public.OperatingSystem')"
          width="100"
        >
          <template v-slot="{ row }">
            <template v-if="row.osId == 20"> Android </template>
            <template v-else-if="row.osId == 10"> iOS </template>
            <template v-else-if="row.osId == 30"> Windows </template>
            <template v-else-if="row.osId == 40"> Linux </template>
          </template>
        </el-table-column>
        <el-table-column
          prop="osId"
          :label="$t('UserManage.user.UserList.DeviceStatus')"
          width="100"
        >
          <template v-slot="{ row }">
            <!-- <template v-if="row.status == 5">
              新建
              <span style="color: #575656">{{$t('UserManage.user.UserList.newConstruction')}}</span>
            </template> -->
            <!-- <template v-if="row.status == 15">
              加入
              <span style="color: #2ba245">{{$t('UserManage.user.UserList.Join')}}</span>
            </template> -->
            <template v-if="row.status == 10">
              <span style="color: #575656">{{
                $t("UserManage.user.UserList.PendingRegistration")
              }}</span>
            </template>
            <!-- 6.1.1版本 -->
            <!-- 未激活 -->
            <template v-if="row.status == 5">
              <span style="color: #2ba245">{{
                $t("UserManage.user.UserList.Notactive")
              }}</span>
            </template>
            <!-- 已激活 -->
            <template v-if="row.status == 15">
              <span style="color: #575656">{{
                $t("UserManage.user.UserList.Active")
              }}</span>
            </template>
            <!-- <template v-if="row.status == 20">
              <span style="color: #1e8ae5">取消激活</span>
            </template> -->
            <template v-if="row.status == 25">
              <span style="color: #1e8ae5">{{
                $t("UserManage.user.UserList.SignOUT")
              }}</span>
            </template>
            <template v-if="row.status == 35">
              <!-- 停用 -->
              <span style="color: #ff0000">{{
                $t("UserManage.user.UserList.SignOUT")
              }}</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop="bindStatus"
          :label="$t('UserManage.user.UserList.BinDingState')"
          width="120"
        >
          <template v-slot="{ row }">
            <template v-if="row.bindStatus == 0">
              <span style="color: #575656">{{
                $t("UserManage.user.UserList.Untie")
              }}</span>
            </template>
            <template v-if="row.bindStatus == 1">
              <span style="color: #2ba245">{{
                $t("UserManage.user.UserList.Bind")
              }}</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="lastLoginTime"
          :label="$t('UserManage.user.UserList.lastLoginTime')"
        >
          <template v-slot="{ row }">
            <span v-if="row.deviceConIMStatus.lastLoginTime == null"></span>
            <span v-else>{{ row.deviceConIMStatus.lastLoginTime }}</span>
          </template>
        </el-table-column>
      </el-table>
      <p style="margin-top: 10px;font-size: 12px;">
        共<b>{{ this.tableDataNmae.length }}</b
        >条记录
      </p>
    </Eldialog>
  </div>
</template>

<script>
import selectInput from "@/components/selectInput";
import radioTree from "@/components/treeSulation/radioTree"; //设置管理员安
import ZhuanYi from "@/components/treeSulation/zhuanyi"; //转移
import naviGation from "@/components/hearder/index"; //头部组件
import pagination from "@/components/pagination/page.vue"; //分页
import dialogInfo from "@/components/promptMessage/index"; //提示消息框
import Tree from "@/components/treeSulation/userTree.vue"; //树形组织
import Eldialog from "@/components/elDialog"; //详情弹框
import AddUser from "./compontesUser/adduser.vue"; //新增
import TheImport from "./compontesUser/theImport.vue"; //导入
import ManageUser from "./compontesUser/manageUser.vue"; //设置为管理员
import locale from "../../../langs/locale";
import utils from "../../../utils/index.js";
export default {
  // 组件
  components: {
    selectInput,
    naviGation, //头部
    pagination, //分页
    dialogInfo, //提示消息
    Tree,
    AddUser, //新增
    TheImport, //导入
    ManageUser, //设置为管理员
    Eldialog,
    radioTree,
    ZhuanYi,
  },
  data() {
    return {
      delarr: [], //删除
      currentPage: "1",
      groupFullNameShowByIndex: "",
      devCount: false, //选中管理员是否有关联设备
      num: 0,
      qUse: [],
      tableDataNmae: [], //关联
      ownerId: "",
      ipt: "",
      dataTolCount: 0,
      statusVisible: false,
      zhuanyi: false,
      bigflag: 1, //大开关
      flag: 1, //大开关里的表格视图切换
      id: "", //左侧组织树点击id的存放
      searchGroup: "", //树状图中查询部门的查询内容
      searchResult: [], //模糊搜索结果
      data: [], //组织树数组
      currentNodekey: 1, //默认高亮（组织树）
      placeholder: "用户名", //默认placeholder为用户名
      tableData: [], //表格数组
      delarr: [], //多个删除存放数组
      trunData: [], //转移存放的数组
      peopleData: [], //设置管理员存放的数组
      syUse: "",
      flagwithe: true,
      flagStatus: true,
      flagStatus1: true,
      flagStatus2: true,
      flagStatus3: true,
      flagStatus4: true,
      flagStatus5: true,
      flagStatus6: true,
      unlockFlag: true, //解锁默认禁用置灰
      info6: "info",
      info5: "info",
      info4: "info",
      info: "info",
      info1: "info",
      info2: "info",
      info3: "info",
      infoUnlock: "info", //新加解锁按钮默认置灰
      radioId: "",
      addArray: null, //新增传递数据
      addArrayId: "", //新增点击传过去组织树的id
      visibaelFlag: false, //提示弹框默认false
      titleInfo: "", //提示titel
      statusVisiblemm: false, //重置密码
      selectVal: {
        deviceName: "",
        InputText: "",
      },
      props: {
        label: "name",
        children: "data",
        isLeaf: "isParent",
      }, //组织树props 提前告知子节点是否还有加载 isLeaf
      userTypes: {
        useType: "",
      }, //用户状态默认类型
      options: [
        {
          value: "",
          label: "用户状态",
        },
        {
          value: "1",
          label: "正常",
        },
        {
          value: "2",
          label: "锁定",
        },
        {
          value: "3",
          label: "停用",
        },
      ], //用户3种状态下拉框
      options1: [
        {
          value: "loginName",
          label: "用户名",
        },
        {
          value: "userName",
          label: "姓名",
        },
        {
          value: "phone",
          label: "电话",
        },
        {
          value: "userGroupName",
          label: "用户组",
        },
      ], //用户名/姓名/电话切换查询下拉框
      queryData: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        orderColume: "u.updateTime",
        orderRule: "DESC",
        input: "",
        loginName: "用户名",
        userName: "",
        phone: "",
      }, //查询条件 （param）
      loginName: "",
      form: {
        // oldPassword: "",//重置密码
        newPassword1: "",
        newPassword2: "",
      },
      rules1: {
        newPassword1: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 8,
            max: 32,
            message: "8~32位，至少包含数字、字母、符号两种元素。",
            trigger: "blur",
          },
          //           密码长度8-32位，须至少包含数字、大写字母、小写字母和符号中的3种
          // new RegExp("^(?![a-zA-Z]+) ( ? ! [ A − Z 0 − 9 ] + )(?![A-Z0-9]+)(?![A−Z0−9]+)(?![A-Z\W_]+) ( ? ! [ a − z 0 − 9 ] + )(?![a-z0-9]+)(?![a−z0−9]+)(?![a-z\W_]+) ( ? ! [ 0 − 9 W ] + )(?![0-9\\W_]+)(?![0−9
          // W
          // ]
          // ​
          //  +)[a-zA-Z0-9\W_]{8,32}$");
          {
            validator: (rule, value, callback) => {
              if (
                /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)/.test(
                  value
                ) == false
              ) {
                callback(
                  new Error("8~32位，至少包含数字、字母、符号两种元素。")
                );
              } else {
                callback();
              }
            },
            trigger: "change", //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
          },
        ],
        newPassword2: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          {
            min: 8,
            max: 32,
            message: "8~32位，至少包含数字、字母、符号两种元素。",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("请输入确认密码"));
              } else if (
                /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)/.test(
                  value
                ) == false
              ) {
                callback(
                  new Error("8~32位，至少包含数字、字母、符号两种元素。")
                );
              } else if (value !== this.form.newPassword1) {
                callback(new Error("两次输入密码不一致!"));
              }
              // else if (value === this.form.oldPassword) {
              //   callback(new Error('新密码不能与原密码相同!'))
              // }
              else {
                callback();
              }
            },
            trigger: "blur", //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
          },
        ],
        clearId: "", //重置密码记录的id
        mangerId: "",
      },
    };
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );

    this.id = sessionStorage.getItem("manageGroupId");
    this.dragControllerDiv(); //树状图外部盒子拖拽
    // 首页跳转
    if (this.$route.query.userFlag == 1) {
      this.userTypes.useType = "1";
      this.dataList();
    } else if (this.$route.query.userFlag == 2) {
      this.userTypes.useType = "2";
      this.dataList();
    } else if (this.$route.query.userFlag == 3) {
      this.userTypes.useType = "3";
      this.dataList();
    } else if (this.$route.query.userFlag == -1) {
      this.userTypes.useType = "";
      this.dataList();
    } else if (this.$route.query.userFlag == 0) {
      this.userTypes.useType = "";
      this.dataList();
    }
    if (document.styleSheets[0].cssRules.length >= 2) {
      document.styleSheets[0].deleteRule(0);
    }
  },
  methods: {
    //限制输入特殊字符
    btKeyUp(e) {
      e.target.value = e.target.value
        .replace(
          /[`~!@#$%^&*()_\-+=<>?:"{}|,/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
          ""
        )
        .replace(/\s/g, "");
    },
    getRowKeys(row) {
      return row.id;
    },
    selectSearchChange() {},
    pasword(val) {
      // 修改密码
      this.statusVisiblemm = true;
      this.clearId = val.id;
      this.loginName = val.loginName;
    },
    async password() {
      // 重置密码
      var param = {
        id: this.clearId + "",
        pass: utils.common.encrypt(
          this.form.newPassword1,
          "SM4",
          $.getSecretKey(this.loginName)
        ),
        newPass: utils.common.encrypt(
          this.form.newPassword2,
          "SM4",
          $.getSecretKey(this.loginName)
        ),
      };
      const res = await this.$axios.post(
        "/httpServe/loginUser/resetPwd",
        param,
        true
      );
      this.$message({
        message: this.$t("UserManage.user.UserList.PasswordSuccessful"),
        type: "success",
        offset: 100,
      });
      this.form = {};
      this.$refs.form.clearValidate();
      this.statusVisiblemm = false;
    },
    changePass(formName) {
      // 修改密码  鼠标移入移出
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.password();
        } else {
          return false;
        }
      });
    },
    heightCustom() {
      // 高度
      this.height.height = this.num;
    },
    ManageSave() {
      this.$message({
        message: "保存成功",
        type: "success",
        offset: 100,
      });
      this.dataList(this.id);
      this.flagStatus1 = true;
      this.flagStatus2 = true;
      this.flagStatus3 = true;
      this.flagStatus4 = true;
      this.flagStatus5 = true;
      this.flagStatus6 = true;
      this.flagStatus = true;
      this.info1 = "info";
      this.info2 = "info";
      this.info3 = "info";
      this.info4 = "info";
      this.info5 = "info";
      this.info6 = "info";
      this.info = "info";
      this.bigflag = 1;
    },
    radioTree(val) {
      this.radioId = val;
    },
    transfer() {
      this.zhuanyi = true;
    },
    onSearch() {}, //树状图查询部门
    focus() {}, //获取焦点
    async inputChange(v) {
      //搜索接口
      if (v.length > 0) {
        this.searchGroup = v;
        var params = { searchName: this.searchGroup };
        const res = await this.$axios.post(
          "/httpServe/groups/selectListByName",
          params,
          true
        );
        this.showFlag = true;
        this.treeShow = false;
        this.searchResult = res.data.content;
      } else {
        this.showFlag = false;
        this.treeShow = true;
      }
    },

    clickList(v) {
      //点击搜索结果
      this.currentNodekey = v.id;
      this.$nextTick(() => {
        this.$refs.tree.setCurrentKey(this.currentNodekey); //一定要加这个选中了否则样式没有出来
      });
      this.dataList(v.id);
    },
    dragControllerDiv: function () {
      //树状图外部盒子拖拽
      var dragPoint = document.getElementsByClassName("dragPoint");
      var leftTree = document.getElementsByClassName("leftTree");
      var leftTable = document.getElementsByClassName("leftTable");
      var leftBox = document.getElementsByClassName("leftBox");
      for (let i = 0; i < dragPoint.length; i++) {
        // 鼠标按下事件
        dragPoint[i].onmousedown = function (e) {
          //颜色改变提醒
          // dragPoint[i].style.background = "#818181";
          var startX = e.clientX; //鼠标按下时鼠标指针的横向坐标
          dragPoint[i].leftTree = dragPoint[i].offsetLeft;
          document.onmousemove = function (e) {
            var endX = e.clientX; //鼠标拖动时鼠标指针的横向坐标
            var moveLen = dragPoint[i].leftTree + (endX - startX); // （endx-startx）=移动的距离。dragPoint[i].left+移动的距离=左边区域最后的宽度
            var maxT = leftBox[i].clientWidth - dragPoint[i].offsetWidth; // 容器宽度 - 左边区域的宽度 = 右边区域的宽度
            if (moveLen < 232) moveLen = 232; // 左边区域的最小宽度为232px
            if (moveLen > maxT - 150) moveLen = maxT; //右边区域最小宽度为150px
            dragPoint[i].style.leftTree = moveLen; // 设置左侧区域的宽度
            for (let j = 0; j < leftTree.length; j++) {
              leftTree[j].style.width = moveLen + "px";
              // leftTable[j].style.width =
              leftBox[i].clientWidth - moveLen - 10 + "px";
            }
          };
          // 鼠标松开事件
          document.onmouseup = function (evt) {
            //颜色恢复
            // dragPoint[i].style.background = "#d6d6d6";
            document.onmousemove = null;
            document.onmouseup = null;
            dragPoint[i].releaseCapture && dragPoint[i].releaseCapture(); //当你不在需要继续获得鼠标消息就要应该调用ReleaseCapture()释放掉
          };
          dragPoint[i].setCapture && dragPoint[i].setCapture(); //该函数在属于当前线程的指定窗口里设置鼠标捕获
          return false;
        };
      }
    },
    trees(val, pid, num) {
      if (val == "1" || pid == "0") {
        this.info4 = "info";
        this.flagStatus4 = true;
        this.info5 = "info";
        this.flagStatus5 = true;
        this.info6 = "info";
        this.flagStatus6 = true;
      } else {
        this.info4 = "primary";
        this.flagStatus4 = false;
        this.info5 = "primary";
        this.flagStatus5 = false;
        this.info6 = "primary";
        this.flagStatus6 = false;
      }
      this.id = val;
      this.num = num;
      this.addArrayId = val + "";
      this.queryData.input = "";
      this.queryData.currentPage = 1;
      this.dataList(this.id);
    },
    closeTree() {
      this.zhuanyi = false;
      this.radioId = "";
    },
    async detezhuanyi() {
      let data = [];
      this.peopleData.forEach((item, index) => {
        data.push(item.id + "");
      });
      var param = {
        ids: data,
        groupId: this.radioId,
      };
      const res = await this.$axios.post(
        "/httpServe/userInfo/transfer",
        param,
        true
      );
      if (res.data == -1) {
        this.$message({
          message: this.$t(
            "UserManage.user.TransferPopUp.pleaseSelectDepartment"
          ),
          type: "error",
          offset: 100,
        });
        this.zhuanyi = true;
      } else {
        this.$message({
          message: this.$t("UserManage.user.TransferPopUp.TransferSuccess"),
          type: "success",
          offset: 100,
        });
        this.$refs.multipleTables.clearSelection(); //清除之前的选中状态
        this.zhuanyi = false;
        this.dataList(this.id);
      }
    },
    //下拉选中查询类型--转译input中placeholder显示
    searchTypeChange(item) {
      this.queryData.loginName = item;
      this.placeholder = item;
      this.options1.forEach((k) => {
        if (item == k.label1) {
          this.seleShift = k.value1;
        }
      });
    },
    // 表格查询
    async onSubmit(val) {
      console.log(val);
      this.selectVal = val;
      this.queryData.currentPage = 1;
      // this.id = ''
      var param = {
        currentPage: this.queryData.currentPage,
        pageSize: this.queryData.pageSize,
        orderColume: this.queryData.orderColume,
        orderRule: this.queryData.orderRule,
        groupId: this.selectVal.InputText.length > 0 ? "" : this.id, //''  不加id 查询全部 this.id,
        status: this.userTypes.useType,
        loginName: val
          ? val.deviceName == "loginName"
            ? val.InputText
            : ""
          : "",
        userName: val
          ? val.deviceName == "userName"
            ? val.InputText
            : ""
          : "",
        phone: val ? (val.deviceName == "phone" ? val.InputText : "") : "",
        userGroupName: val
          ? val.deviceName == "userGroupName"
            ? val.InputText
            : ""
          : "",
      };
      const res = await this.$axios.post(
        "/httpServe/userInfo/getDataInfo",
        param,
        true
      );
      this.tableData = res.data.content;
      this.tableData.forEach((item) => {
        if (item.email && item.email.trim()) {
          item.phone = this.$utils.common.decrypt(item.phone, item.userName);
        }
      });
      this.tableData.forEach((item) => {
        if (item.email && item.email.trim()) {
          item.email = this.$utils.common.decrypt(item.email, item.userName);
        }
      });
      this.queryData.total = res.data.total;
    },
    // 表格列表
    async dataList(v) {
      this.id = v;
      // 首页跳转
      if (this.$route.query.userFlag == 1) {
        this.userTypes.useType = "1";
      } else if (this.$route.query.userFlag == 2) {
        this.userTypes.useType = "2";
      } else if (this.$route.query.userFlag == 3) {
        this.userTypes.useType = "3";
      } else if (this.$route.query.userFlag == -1) {
        this.userTypes.useType = "";
      } else if (this.$route.query.userFlag == 0) {
        this.userTypes.useType = "";
      }
      console.log(this.userTypes.useType, "--this.userTypes.useType");
      let token2 = sessionStorage.getItem("Authorization")
        ? sessionStorage.getItem("Authorization")
        : "eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE2MzMwMjMzNzUsInVzZXJJZCI6MSwiaWF0IjoxNjMzMDAxNzc1fQ.hSF4bNDHVFZR2h4R0osNBF56ChR_l03mwx9QYaS1E0JDtIFlme3dP8oktCjEIaGi4QzXutLRMmjFWxuDNtaMMA";

      let userString = decodeURIComponent(
        escape(window.atob(token2.split(".")[1]))
      );

      this.userId = JSON.parse(userString).userId;

      var param = {
        userId: this.userId,
        currentPage: this.queryData.currentPage,
        pageSize: this.queryData.pageSize,
        orderColume: this.queryData.orderColume,
        orderRule: this.queryData.orderRule,
        groupId: this.selectVal.InputText.length > 0 ? "" : this.id, //''  不加id 查询全部 this.id,
        status: this.userTypes.useType,
        loginName: this.selectVal.deviceName
          ? this.selectVal.deviceName == "loginName"
            ? this.selectVal.InputText
            : ""
          : "",
        userName: this.selectVal.deviceName
          ? this.selectVal.deviceName == "userName"
            ? this.selectVal.InputText
            : ""
          : "",
        phone: this.selectVal.deviceName
          ? this.selectVal.deviceName == "phone"
            ? this.selectVal.InputText
            : ""
          : "",
        userGroupName: this.selectVal.deviceName
          ? this.selectVal.deviceName == "userGroupName"
            ? this.selectVal.InputText
            : ""
          : "",
      };
      const res = await this.$axios.post(
        "/httpServe/userInfo/getDataInfo",
        param,
        true
      );
      this.tableData = res.data.content;
      this.tableData.forEach((item) => {
        if (item.phone && item.phone.trim()) {
          item.phone = this.$utils.common.decrypt(item.phone, item.userName);
        }
      });
      this.tableData.forEach((item) => {
        if (item.email && item.email.trim()) {
          item.email = this.$utils.common.decrypt(item.email, item.userName);
        }
      });
      this.queryData.total = res.data.total;
    },
    // 排序
    sort(column) {
      console.log(column.order, column.prop);
      if (column.prop == "u.loginName") {
        if (column.order == "ascending") {
          this.queryData.orderColume = "u.loginName";
          this.queryData.orderRule = "ASC";
        } else {
          this.queryData.orderColume = "u.loginName";
          this.queryData.orderRule = "DESC";
        }
      } else if (column.prop == "u.userName") {
        if (column.order == "ascending") {
          this.queryData.orderColume = "u.userName";
          this.queryData.orderRule = "ASC";
        } else {
          this.queryData.orderColume = "u.userName";
          this.queryData.orderRule = "DESC";
        }
      } else if (column.prop == "u.userSex") {
        if (column.order == "ascending") {
          this.queryData.orderColume = "u.userSex";
          this.queryData.orderRule = "ASC";
        } else {
          this.queryData.orderColume = "u.userSex";
          this.queryData.orderRule = "DESC";
        }
      } else if (column.prop == "g.groupFullName") {
        if (column.order == "ascending") {
          this.queryData.orderColume = "g.groupFullName";
          this.queryData.orderRule = "ASC";
        } else {
          this.queryData.orderColume = "g.groupFullName";
          this.queryData.orderRule = "DESC";
        }
      } else if (column.prop == "u.phone") {
        if (column.order == "ascending") {
          this.queryData.orderColume = "u.phone";
          this.queryData.orderRule = "ASC";
        } else {
          this.queryData.orderColume = "u.phone";
          this.queryData.orderRule = "DESC";
        }
      } else if (column.prop == "u.email") {
        if (column.order == "ascending") {
          this.queryData.orderColume = "u.email";
          this.queryData.orderRule = "ASC";
        } else {
          this.queryData.orderColume = "u.email";
          this.queryData.orderRule = "DESC";
        }
      } else if (column.prop == "u.deviceCount") {
        if (column.order == "ascending") {
          this.queryData.orderColume = "u.deviceCount";
          this.queryData.orderRule = "ASC";
        } else {
          this.queryData.orderColume = "u.deviceCount";
          this.queryData.orderRule = "DESC";
        }
      } else if (column.prop == "u.status") {
        if (column.order == "ascending") {
          this.queryData.orderColume = "u.status";
          this.queryData.orderRule = "ASC";
        } else {
          this.queryData.orderColume = "u.status";
          this.queryData.orderRule = "DESC";
        }
      } else if (column.prop == "u.updateTime") {
        if (column.order == "ascending") {
          this.queryData.orderColume = "u.updateTime";
          this.queryData.orderRule = "ASC";
        } else {
          this.queryData.orderColume = "u.updateTime";
          this.queryData.orderRule = "DESC";
        }
      } else if (column.prop == "userGroupName") {
        if (column.order == "ascending") {
          this.queryData.orderColume = "userGroupName";
          this.queryData.orderRule = "ASC";
        } else {
          this.queryData.orderColume = "userGroupName";
          this.queryData.orderRule = "DESC";
        }
      }
      this.dataList(this.id);
    },
    // 1、单选是正常的用户--停用高亮--启用跟解锁置灰；停用的用户--启用高亮，停用跟解锁置灰；锁定的用户--解锁高亮，停用跟启用置灰
    // 2、批量选择全部高亮，、，能执行的执行，不能执行的不执行（启用-只针对停用；锁定和正常都可以停用）
    handleSelectionChange(val) {
      //表格当删除发生变化
      this.qUse = [...val];
      this.delarr = [...val];
      this.trunData = [...val];
      this.peopleData = [...val];
      let dataArr = [...val];
      if (dataArr.length == 1) {
        dataArr.forEach((element) => {
          if (element.status == 1) {
            //正常
            this.info = "primary";
            this.info1 = "primary";
            this.info2 = "primary"; //停用
            this.info3 = "info"; //启用
            this.infoUnlock = "info"; //解锁
            this.unlockFlag = true; //解锁
            this.flagStatus = false;
            this.flagStatus1 = false;
            this.flagStatus2 = false;
            this.flagStatus3 = true; //启用
          }
          if (element.status == 2) {
            //锁定
            this.info = "primary";
            this.flagStatus = false;
            this.info1 = "primary";
            this.flagStatus1 = false;
            this.info2 = "info"; //停用
            this.flagStatus2 = true;
            this.info3 = "info"; //启用
            this.flagStatus3 = true;
            this.infoUnlock = "primary";
            this.unlockFlag = false;
          }
          if (element.status == 3) {
            //停用
            this.info = "primary";
            this.info1 = "primary";
            this.info2 = "info"; //停用
            this.info3 = "primary"; //启用
            this.infoUnlock = "info";
            this.unlockFlag = true;
            this.flagStatus = false;
            this.flagStatus1 = false;
            this.flagStatus2 = true;
            this.flagStatus3 = false;
          }
        });
      } else if (dataArr.length >= 2) {
        this.info = "primary";
        this.info1 = "primary";
        this.info2 = "primary";
        this.info3 = "primary";
        this.flagStatus = false;
        this.flagStatus1 = false;
        this.flagStatus2 = false;
        this.flagStatus3 = false;
        this.unlockFlag = false;
        this.infoUnlock = "primary";
      } else if (dataArr.length < 1) {
        this.info = "info";
        this.info1 = "info";
        this.info2 = "info";
        this.info3 = "info";
        this.infoUnlock = "info";
        this.flagStatus = true;
        this.flagStatus1 = true;
        this.flagStatus2 = true;
        this.flagStatus3 = true;
        this.unlockFlag = true;
      }
      if (dataArr.length > 1) {
        //设置为管理员大于1置灰
        this.info1 = "info";
        this.flagStatus1 = true;
      }
      //解锁按钮可点击
      // if(this.delarr.length>0){
      //   this.delarr.forEach((item)=>{
      //     console.log(item,"item")
      //     if(item.status== 2){
      //       // i++
      // this.unlockFlag=false
      // this.infoUnlock='primary'
      //     }else{
      //       this.unlockFlag=true
      //       this.infoUnlock='info'
      //     }
      //   })
      // }
      // let obj = dataArr.pop()
      // if (obj && obj.id) {
      //   this.syUse = obj.id
      // }
      // if (obj && obj.status == 1) {
      //   this.info2 = 'primary'
      //   this.flagStatus2 = false
      //   this.infoUnlock = 'primary'
      //   this.unlockFlag = false
      //   this.info3 = 'primary'
      //   this.flagStatus3 = false

      // }
      // if (obj && obj.status == 2) {
      // this.info2 = 'info'
      // this.flagStatus2 = true
      // this.info3 = 'info'
      // this.flagStatus3 = true
      // this.infoUnlock = 'primary'
      // this.unlockFlag = false
      // }
      // if (obj && obj.status == 3) {
      // this.info2 = 'info'
      // this.flagStatus2 = true
      // this.info3 = 'primary'
      // this.flagStatus3 = false
      // this.infoUnlock = 'info'
      // this.unlockFlag = true
      // }
      // if (this.trunData.length > 1) {
      //   this.info1 = 'info'
      //   this.flagStatus1 = true
      //   this.flagStatus = false
      // }
      // if (this.trunData.length > 2) {
      //   this.trunData.forEach((item, index) => {
      //     if (this.trunData[index].status !== this.trunData[index + 1].status) {
      //       this.info2 = 'info'
      //       this.flagStatus2 = true
      //       this.info3 = 'info'
      //       this.flagStatus3 = true
      //     }
      //   })
      // }
    },
    // 禁用
    selectEnable(row, rowIndex) {
      console.log(row, "row", row.id, "row.id");
      if (row.id == "4") {
        return false;
      } else {
        return true;
      }
    },
    // 分页
    handleParentGetList(page, limit) {
      this.queryData.currentPage = page;
      this.queryData.pageSize = limit;
      // 调用列表
      this.dataList(this.id);
    },
    TYbtn() {
      this.$confirm(
        this.$t("UserManage.user.UserList.OutOfService"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(() => {
          this.tiyong();
        })
        .catch(() => {
          return false;
        });
    },
    async tiyong() {
      const ids = this.peopleData.map((item) => item.id); 
      let param = {
        status: "3",
        ids: ids,
      };
      const res = await this.$axios.post(
        "/httpServe/userInfo/updateStatus",
        param,
        true
      );
      console.log(res,'-----------');
      if(res.data == 0){
        this.$message({
          message: this.$t("UserManage.user.UserList.userTingSuccess"),
          type: "success",
          offset: 100,
        });
      }else{
        this.$message({
          message: this.$t("UserManage.user.UserList.userTingError"),
          type: "error",
          offset: 100,
        });
      }
      
      this.$refs.multipleTables.clearSelection();
      this.dataList(this.id);
    },
    QYbtn() {
      this.$confirm(
        this.$t("UserManage.user.UserList.enable"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(() => {
          this.qiyong();
        })
        .catch(() => {
          return false;
        });
    },
    async qiyong() {
      const ids = this.peopleData.map((item) => item.id);
      let param = {
        status: "5",
        ids: ids,
      };
      console.log(param,'启用');
      const res = await this.$axios.post(
        "/httpServe/userInfo/updateStatus",
        param,
        true
      );
      if(res.data == 0){
        this.$message({
          message: this.$t("UserManage.user.UserList.userQiSuccess"),
          type: "success",
          offset: 100,
        });
      }else{
        this.$message({
          message: this.$t("UserManage.user.UserList.userQiError"),
          type: "error",
          offset: 100,
        });
      }
      this.$refs.multipleTables.clearSelection();
      this.dataList(this.id);
    },
    delobj(obj) {
      this.delarr = obj;
    },
    delMuch(obj) {
      // 多选删除
      var ids = [];
      obj.forEach((item) => {
        ids.push(item.id);
        if (item.deviceCount >= 1) {
          this.devCount = true;
        } else {
          this.devCount = false;
        }
      });
      var parma = { ids: ids };
      if (this.devCount) {
        this.$message({
          message: this.$t("UserManage.user.UserList.delMessage"),
          type: "error",
          offset: 100,
        });
        this.$refs.multipleTables.clearSelection();
      } else {
        this.$confirm(
          this.$t("UserManage.user.UserList.confirmMessage"),
          this.$t("public.PromptMessage"),
          {
            confirmButtonText: this.$t("public.Verify"),
            cancelButtonText: this.$t("public.Cancel"),
            type: "warning",
            customClass: "persdsd",
          }
        )
          .then(async () => {
            const res = await this.$axios.post(
              "/httpServe/userInfo/delete",
              parma,
              true
            );
            if (res.data == -1) {
              this.$message({
                message: this.$t("UserManage.user.UserList.delMessage"),
                type: "error",
                offset: 100,
              });
              this.$refs.multipleTables.clearSelection();
            } else {
              this.$message({
                message: this.$t("public.SuccessfullyDeleted"),
                type: "success",
                offset: 100,
              });
            }
            this.queryData.currentPage = "1";
            this.dataList(this.id);
            this.$refs.multipleTables.clearSelection();
          })
          .catch(() => {
            // this.$message({
            //   message: '删除失败',
            //   type: 'error',
            //   offset: 100
            // })
            return false;
          });
      }
    },
    // 单个删除
    del(id) {
      this.$confirm(
        this.$t("UserManage.user.UserList.confirmMessage"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(async () => {
          // 请求接口
          var parma = { ids: [String(id)] };
          const res = await this.$axios.post(
            "/httpServe/userInfo/delete",
            parma,
            true
          );
          // this.visibaelFlag = true;
          // this.titleInfo = "删除成功";
          if (res.data == -1) {
            this.$message({
              message: this.$t("UserManage.user.UserList.delMessage"),
              type: "error",
              offset: 100,
            });
          } else {
            this.$message({
              message: this.$t("public.SuccessfullyDeleted"),
              type: "success",
              offset: 100,
            });
          }
          this.$refs.multipleTables.clearSelection();
          this.queryData.currentPage = "1";
          this.dataList(this.id);
        })
        .catch(() => {
          return false;
        });
    },
    addButtons(val) {
      if (val == 0) {
        this.flag = 1;
        document.styleSheets[0].deleteRule(0);
        this.$message({
          message: this.$t("public.SuccessfullySaved"),
          type: "success",
          offset: 100,
        });
        this.dataList(this.id);
      } else if (val == 2) {
        this.flag = 2;
        this.$message({
          message: this.$t("UserManage.user.addList.saveError"),
          type: "error",
          offset: 100,
        });
      } else if (val == 3) {
        this.flag = 1;
        document.styleSheets[0].deleteRule(0);
        this.$message({
          message: this.$t("public.SuccessfullySaved"),
          type: "success",
          offset: 100,
        });
        this.dataList(this.id);
      }
    },
    // 新增打开flag
    addBtn() {
      this.addArray = {};
      this.disabled = false;
      document.styleSheets[0].insertRule("#myDiv {pointer-events: none;}", 0);
      this.flag = 2;
    },
    // 编辑打开edit
    edit(val) {
      this.addArray = val;
      document.styleSheets[0].insertRule("#myDiv {pointer-events: none;}", 0);

      this.flag = 2;
    },
    //解锁icon
    unlockIncident(val) {
      var idStr = val.id.toString();
      var parmas = {
        status: "1", // 1-正常 2-锁定 ids: val.id
        ids: [idStr],
      };
      this.$confirm(
        this.$t("UserManage.user.UserList.LockMessage"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            "/httpServe/userInfo/updateStatus",
            parmas,
            true
          );
          if (res.data == -1) {
            this.$message({
              message: this.$t("UserManage.user.UserList.LockErrorMessage"),
              type: "error",
              offset: 100,
            });
            this.$refs.multipleTables.clearSelection();
            this.dataList(this.id);
          }
          if (res.data == 0) {
            this.$message({
              message: this.$t(
                "UserManage.user.UserList.LockSuccessfulMessage"
              ),
              type: "success",
              offset: 100,
            });
            this.$refs.multipleTables.clearSelection();
            this.dataList(this.id);
          }
        })
        .catch(() => {
          return false;
        });
    },
    //解锁
    unlockBtn(obj) {
      var ids = [];
      obj.forEach((item) => {
        ids.push(item.id);
      });
      ids = ids.map(Number); //将arr1转换为数值数组
      var parmas = {
        status: "1", // 1-正常 2-锁定 ids: val.id
        ids: ids,
      };
      this.$confirm(
        this.$t("UserManage.user.UserList.LockMessage"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            "/httpServe/userInfo/updateStatus",
            parmas,
            true
          );
          if (res.data == -1) {
            this.$message({
              message: this.$t("UserManage.user.UserList.LockErrorMessage"),
              type: "error",
              offset: 100,
            });
            this.$refs.multipleTables.clearSelection();
            this.dataList(this.id);
          }
          if (res.data == 0) {
            this.$message({
              message: this.$t(
                "UserManage.user.UserList.LockSuccessfulMessage"
              ),
              type: "success",
              offset: 100,
            });
            this.$refs.multipleTables.clearSelection();
            this.dataList(this.id);
          }
        })
        .catch(() => {
          return false;
        });
    },
    // 导入
    theInport() {
      this.flag = 5;
    },
    // 导入
    theImports() {
      this.flag = 1;
      document.styleSheets[0].deleteRule(0);
      // this.visibaelFlag = true
      this.$message({
        message: this.$t("UserManage.user.UserList.ImportSuccessfully"),
        type: "success",
        offset: 100,
      });
      this.dataList(this.id);
    },
    // 导出
    async resetForm() {
      let token2 = sessionStorage.getItem("Authorization")
        ? sessionStorage.getItem("Authorization")
        : "eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE2MzMwMjMzNzUsInVzZXJJZCI6MSwiaWF0IjoxNjMzMDAxNzc1fQ.hSF4bNDHVFZR2h4R0osNBF56ChR_l03mwx9QYaS1E0JDtIFlme3dP8oktCjEIaGi4QzXutLRMmjFWxuDNtaMMA";

      let userString = decodeURIComponent(
        escape(window.atob(token2.split(".")[1]))
      );

      // let userString = decodeURIComponent(
      //   escape(window.atob(this.token.split(".")[1]))
      // );
      this.userId = JSON.parse(userString).userId;

      var param = {
        orderColume: this.queryData.orderColume,
        orderRule: this.queryData.orderRule,
        groupId: this.id,
        userId: this.userId,
        status: this.userTypes.useType,
        loginName: this.selectVal.deviceName
          ? this.selectVal.deviceName == "loginName"
            ? this.selectVal.InputText
            : ""
          : "",
        userName: this.selectVal.deviceName
          ? this.selectVal.deviceName == "userName"
            ? this.selectVal.InputText
            : ""
          : "",
        phone: this.selectVal.deviceName
          ? this.selectVal.deviceName == "phone"
            ? this.selectVal.InputText
            : ""
          : "",
        userGroupName: this.selectVal.deviceName
          ? this.selectVal.deviceName == "userGroupName"
            ? this.selectVal.InputText
            : ""
          : "",
      };

      if (this.queryData.loginName == "用户名" && this.queryData.input) {
        param.loginName = this.queryData.input;
      }
      if (this.queryData.loginName == "姓名" && this.queryData.input) {
        param.userName = this.queryData.input;
      }
      if (this.queryData.loginName == "电话" && this.queryData.input) {
        param.phone = this.queryData.input;
      }
      const res = await this.$axios.postExport(
        "/httpManageExport/userInfo/exportFile",
        param,
        false,
        this.userId
      );
      const link = document.createElement("a");
      var fileName = "exportUserInfo_" + Date.parse(new Date()) + ".xls";
      let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", decodeURI(fileName));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // 弹框点击关闭事件
    handleClose() {
      this.visibaelFlag = false; //提示消息弹框关闭
      this.statusVisiblemm = false;
      this.statusVisible = false;
      this.ipt = "";
      this.form = {};
      this.$refs["form"].resetFields();
    },
    //确定的关闭
    determine() {
      this.visibaelFlag = false;
      this.changePass();
    },
    //  管理员按钮打开
    tubePeople(val) {
      this.ManageUser = val;
      this.bigflag = 2;
    },
    ManageUsers() {
      this.bigflag = 2;
    },
    updateData() {
      let arr = this.tableDataNmae;
      let list = arr.filter(
        (item) =>
          item.deviceName.toLowerCase().indexOf(this.ipt.toLowerCase()) !== -1
      );
      this.tableDataNmae = list;
      if (!this.ipt) {
        this.nameApp(this.deviceCount);
      }
    },
    // 点击名称详情
    async nameApp(val) {
      this.ownerId = val.id;
      this.deviceCount = { ...val };
      if (val.deviceCount > 0) {
        this.statusVisible = true;
        var param = {
          ownerId: this.ownerId,
        };
        const res = await this.$axios.post(
          "/httpServe/device/selectListByOwnerId",
          param,
          true
        );
        this.tableDataNmae = res.data.content;
        this.dataTolCount = res.data.total;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table__row > td {
  border: none;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table th.is-leaf {
  border: none;
}
.mt15 {
  padding: 10px 10px 0px;
}
.buttonGroupDiv {
  padding: 0px 10px 0px 10px;
}
//重置密码弹框表单
.restForm {
  ::v-deep .el-form-item {
    margin-bottom: 30px;
  }
}

.liHover:hover {
  cursor: pointer;
  background-color: #ffe6b0;
}

.leftBox,
.rightBox {
  // height: 100%;
  // max-height: 881px;
  // border: 2px solid #1d89e3;
  // padding: 3px 10px 0;
  box-sizing: border-box;
  font-size: 12px;
}

//左侧
.leftBox {
  //第一个div内左侧树状图部分
  float: left;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  // pointer-events:none;//元素不响应鼠标事件，包括禁用鼠标事件及对鼠标事件作出响应
  // pointer-events:auto;//默认值，元素响应鼠标事件
  .leftTree {
    float: left;
    height: 700px;
    max-height: 750px;
    border-radius: 3px;
    width: 230px;
    position: relative;
    border: 1px solid #cdd1dd;
    padding: 10px 10px 0px 15px;
    .wrap_tree {
      pointer-events: auto; //默认值，元素响应鼠标事件
    }
    //鼠标拖拽图标
    .dragPoint {
      width: 21px;
      height: 21px;
      position: absolute;
      z-index: 2;
      background: #fff;
      top: 47%;
      margin-top: -15px;
      cursor: col-resize;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
    }

    //树状图
    .treeBox {
      margin-top: 0px;
      height: 650px;
      overflow-y: auto;
    }
  }

  .wrap_tree {
    position: relative;

    .historyBox {
      position: absolute;
      top: 30px;
      background-color: white;
      z-index: 99900;
      width: 200px;
      overflow: hidden auto;
      padding: 8px 0px;
      max-height: 376px;
      box-shadow: 0px 0px 10px rgb(232, 232, 232);
      border-radius: 4px;

      ul {
        li {
          padding: 5px 0px;
          margin: 0px 5px;
        }

        li:hover {
          background-color: #e8e8e9;
          cursor: pointer;
        }
      }
    }

    .fuzzySearch {
      width: 200px;
      overflow: hidden auto;
      padding: 8px 0px;
      max-height: 376px;
      box-shadow: 0px 0px 10px rgb(232, 232, 232);

      ul {
        li:hover {
          background-color: #e8e8e9;
          cursor: pointer;
        }

        li {
          height: 30px;
          line-height: 30px;
          padding-left: 14px;
          border: 1px solid #c6c6c6;
          color: gray;
          margin-top: -1px;
        }
      }
    }

    //树状图
    .treeBox {
      padding-top: 10px;

      .el-tree-node:focus > .el-tree-node__content {
        background-color: #ffe6b0;
        color: black;
        border: 1px #ffb951 solid;
        // height: 18px;
        opacity: 0.8;
      }

      .el-tree-node__content {
        &:hover {
          background-color: #ffe6b0;
        }
      }

      .el-tree-node__content .el-tree-node__label {
        font-size: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
      }
    }
  }

  .liHover:hover {
    cursor: pointer;
    background-color: #ffe6b0;
  }
}
</style>
<style>
.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #ffe6b0;
}
</style>