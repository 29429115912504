<template>
  <div style="width: 500px; height: 1600px; margin-top: 20px; margin-left: 65px">
    <el-form size="small" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item :label="$t('UserManage.user.SetAdministrator.username')" prop="loginName">
        <el-input style="width: 250px" :disabled="disabled" v-model="ruleForm.loginName"></el-input>
      </el-form-item>
      <el-form-item :label="$t('UserManage.user.SetAdministrator.name')" prop="userName">
        <el-input style="width: 250px" v-model="ruleForm.userName"></el-input>
      </el-form-item>
      <el-form-item :label="$t('UserManage.user.SetAdministrator.telephone')" prop="phone">
        <el-input style="width: 250px" v-model="ruleForm.phone"></el-input>
      </el-form-item>
      <el-form-item :label="$t('UserManage.user.SetAdministrator.mailbox')" prop="email">
        <el-input style="width: 250px" v-model="ruleForm.email"></el-input>
      </el-form-item>
      <el-form-item :label="$t('UserManage.user.SetAdministrator.sex')">
        <el-select style="width: 250px" v-model="ruleForm.userSex" placeholder="请选择活动区域">
          <el-option :label="$t(`UserManage.user.SetAdministrator.male`)" value="0"></el-option>
          <el-option :label="$t(`UserManage.user.SetAdministrator.female`)" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('UserManage.user.SetAdministrator.role')">
        <el-select style="width: 250px" v-model="ruleForm.userRole" @change="changeEvent" placeholder="请选择活动区域">
          <el-option v-for="(item, index) in dataRoleList" :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('UserManage.user.SetAdministrator.section')" :required="true">
        <div style="display: flex">
          <div>
            <el-input style="width: 250px" type="textarea" :disabled="disabled" :autosize="{ minRows: 2, maxRows: 4 }" v-model="ruleForm.groupFullName"></el-input>
          </div>
          <div>
            <el-button style="margin-left: 5px" type="primary" @click="groupButton">{{$t('UserManage.user.SetAdministrator.ModifySection')}} </el-button>
          </div>
        </div>
      </el-form-item>
      <el-form-item v-if="flagwithe" :label="$t('UserManage.user.SetAdministrator.AdministrativeDepartment')" :required="true">
        <div style="display: flex">
          <div @click="manageGroupButton">
            <el-input style="width: 250px" type="textarea" :disabled="disabled" readonly="readonly" :autosize="{ minRows: 2, maxRows: 4 }" v-model="ruleForm.manageGroupFullName"></el-input>
          </div>
          <div>
            <el-button style="margin-left: 5px" type="primary" @click="manageGroupButton">{{ $t('UserManage.user.SetAdministrator.RevisionManagement') }}</el-button>
          </div>
        </div>
      </el-form-item>

      <el-form-item style="margin-bottom: 100px">
        <el-button type="primary" @click="peopleBtn('ruleForm')">
          {{$t('public.Save')}}
        </el-button>
      </el-form-item>
    </el-form>

    <!-- =部门组织树结构弹框 -->
    <Eldialog :num="'470px'" :title="$t('UserManage.user.SetAdministrator.SelectDepartment')" :dialogVisible="groupVisible" v-if="groupVisible" @handleClose="handleClose" :flagbtn="true" :btnTitle="'确定'" :cancelBtn="true" :determineBtn="true" @determine="queding">
      <radioTree @radioTree="radioTree1" />
    </Eldialog>
    <!-- =管理部门组织树结构弹框 -->
    <Eldialog :num="'470px'" :title="$t('UserManage.user.SetAdministrator.SelectAdministration')" :dialogVisible="managegroupVisible" v-if="managegroupVisible" @handleClose="handleClose" :flagbtn="true" :btnTitle="'确定'" :cancelBtn="true" :determineBtn="true" @determine="qd">
      <radioTrees :flag="false" @radioTree="radioTree2" />
    </Eldialog>
    <dialogInfo :dialogInfo="visibaelFlag" @handleClose="handleClose1" @determine="determine" :titleInfo="titleInfo" :width="'240px'" />
  </div>
</template>

<script>
import dialogInfo from '@/components/promptMessage/index' //提示消息框
import radioTree from '@/components/treeSulation/mangerTree' //单选的框（组织树结构）
import radioTrees from '@/components/treeSulation/mangerssu'
import Tree from '@/components/treeSulation/treeEncapsulation.vue' //树形组织
import Eldialog from '@/components/elDialog' //详情弹框
import transferVue from '../../../contentmanagement/components/transfer.vue'
export default {
  props: {
    addArray: {
      type: Object,
      default: () => {}
    },
    addArrayId: {
      type: String,
      default: ''
    }
  },
  // 组件
  components: {
    Tree,
    radioTree,
    radioTrees,
    dialogInfo,
    Eldialog
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      undefined
      if (value === '') {
        undefined

        callback(new Error('请输入手机号'))
      } else {
        undefined

        if (!/^[0-9]*$/.test(value)) {
          undefined

          callback(new Error('电话必须是正确的手机（电话）号码'))
        } else {
          undefined

          callback()
        }
      }
    }
    let validcodeName = (rule, value, callback) => {
      // ^\a-\z\A-\Z0-9\u4E00-\u9FA5
      // ^<>.,?;:'()!~%-_@#%/*""\s]+$"不允许输入特殊符号和字符
      let reg = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/
      if (!reg.test(value)) {
        callback(new Error('不支持空格及特殊字符'))
      } else {
        callback()
      }
    }
    return {
      groupFullNameShowByIndex: '',
      radioshu: '',
      radioOperId: '',
      radioshu2: '',
      radio1Id: '',
      groupFlag: false, //部门弹框默认FALSE
      groupVisible: false, //部门弹框关闭默认FALSE
      managegroup: false, //管理部门弹框默认FALSE
      managegroupVisible: false, //管理部门弹框关闭默认FALSE
      titleInfo: '', //提示消息
      visibaelFlag: false, //提示消息默认FALSE
      disabled: false,
      dataRoleList: [], //存放角色的数组
      xuanzhong: false, //父节点默认不可选中
      data: [], //存放组织树数组
      flagwithe: true,
      radioOper: '',
      radio1: '',
      ruleForm: {
        loginName: '', //用户名
        userName: '', //姓名
        phone: '', //电话
        email: '', //邮箱
        userSex: '0', //性别，默认男
        action: '', //新增传参
        groupId: '', //点击树的groupid
        userRole: '',
        filesName: '',
        groupFullName: '',
        manageGroupFullName: ''
      },
      rules: {
        loginName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },

          {
            pattern: /^[0-9a-zA-Z_]{1,}$/,
            message: '用户名只支持英文（大小写）、数字或下划线组成',
            trigger: 'blur'
          },
          {
            min: 4,
            max: 32,
            message: '长度在 4 到 32 个字符',
            trigger: 'blur'
          }
        ],
        userName: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { validator: validcodeName, trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号' },
          { validator: validatePhone, trigger: 'blur' },
          {
            min: 1,
            max: 12,
            message: '最长输入12个字符，仅支持数字',
            trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: ['blur', 'change']
          }
        ]
        // groupFullName: [
        //   { required: true, message: "请输选择部门", trigger: "blur" },
        // ],
        // manageGroupFullName: [
        //   { required: true, message: "请输选择管理部门", trigger: "blur" },
        // ],
      }
    }
  },
  created() {
    // this.ruleForm = this.addArray;
    // this.disabled = true;
    // this.currentNodekey = this.addArrayId;
    // this.$set(this.ruleForm, "loginName", this.addArray.loginName);
    // this.$set(this.ruleForm, "userName", this.addArray.userName);
    // this.$set(this.ruleForm, "phone", this.addArray.phone);
    // this.$set(this.ruleForm, "email", this.addArray.email);
    // this.$set(this.ruleForm, "userSex", this.addArray.userSex + "");
    // this.$set(this.ruleForm, "id", this.addArray.id);
    // this.$set(this.ruleForm, "userRole", 1);
    // }
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      'groupFullNameShowByIndex'
    )
    this.radioOper = this.addArray.groupId
 

    if (this.addArray) { 
      this.ruleForm.groupFullName = this.addArray.groupFullName.substr(
        this.groupFullNameShowByIndex
      ) 
      this.ruleForm.manageGroupFullName ==
        this.addArray.groupFullName.substr(this.groupFullNameShowByIndex)
      this.disabled = true
      this.currentNodekey = this.addArrayId
      this.$set(this.ruleForm, 'loginName', this.addArray.loginName)
      this.$set(this.ruleForm, 'userName', this.addArray.userName)
      this.$set(this.ruleForm, 'phone', this.addArray.phone)
      this.$set(this.ruleForm, 'email', this.addArray.email)
      this.$set(this.ruleForm, 'userSex', this.addArray.userSex + '')
      this.$set(this.ruleForm, 'id', this.addArray.id)
      // this.$set(this.ruleForm, 'userRole', 1)
    }
    this.roleData() //角色
  },
  methods: {
    queding() {
      // this.radioOper = this.radioOperId; 
      if (!this.radioshu) {
        this.groupVisible = true
        this.$message({
          message: this.$t('UserManage.user.SetAdministrator.pleaseSelectDepartment'),
          type: 'error',
          offset: 100
        })
      } else {
        // this.ruleForm.groupFullName = this.radioshu;
        let arr = this.radioshu
        this.ruleForm.groupFullName = arr.substr(this.groupFullNameShowByIndex) //单选框选中管理部门名称---暂时存放 
        this.groupVisible = false
      }
    },
    qd() {
      if (!this.radioshu2) {
        this.radio1 = ''
        // this.radioshu2 = ''
        this.managegroupVisible = true
        this.$message({
          message: this.$t('UserManage.user.SetAdministrator.pleaseSelectAdministration'),
          type: 'error',
          offset: 100
        })
      } else {
        // this.ruleForm.manageGroupFullName = this.radioshu2;
        let arr = this.radioshu2
        this.ruleForm.manageGroupFullName = arr.substr(
          this.groupFullNameShowByIndex
        ) //单选框选中管理部门名称---暂时存放
        this.radio1 = this.radio1Id
        this.managegroupVisible = false
      }
    },
    // 部门按钮点击
    groupButton() {
      this.groupVisible = true
    },
    // 管理部门按钮点击
    manageGroupButton() {
      this.managegroupVisible = true
    },
    handleClose() {
      this.groupVisible = false //部门
      this.managegroupVisible = false
    },
    handleClose1() {
      this.visibaelFlag = false //提示消息
    },
    //确定的关闭
    determine() {
      this.visibaelFlag = false //提示消息
      this.groupVisible = false //部门
    },
    radioTree1(val, item) {
      console.log(val, item,'val, item111111111');
      if (item.id == sessionStorage.getItem('manageGroupId')) {
        this.radioshu = ''
      } else {
        this.radioshu = item.groupFullName + '•' + item.name
        this.radioOper = val
      }
    },
    radioTree2(val, item) { 
      console.log(val, item,'val, item222222222',this.radioshu2);
      if (item.id == sessionStorage.getItem('manageGroupId')) {
        this.radioshu2 = ''
      } else {
        this.radioshu2 = item.groupFullName + '•' + item.name
        this.radio1Id = val
      }
    },
    async peopleSubmit() {
      // if (!this.radioOper) {
      //   this.titleInfo = "请选择部门";
      //   this.visibaelFlag = true;
      // } else
      if (this.flagwithe === true&&this.radio1 == "") {
        this.$message({
          message: this.$t('UserManage.user.SetAdministrator.pleaseSelectAdministration'),
          type: 'error',
          offset: 100
        })
      } else {
        this.ruleForm.id += ''
        this.ruleForm.action = 'toAdmin'
        this.ruleForm.groupId = this.radioOper
        this.ruleForm.manageGroupId = this.radio1 
        const res = await this.$axios.post(
          '/httpServe/userInfo/toAdmin',
          this.ruleForm,
          true
        )
        if (res.data == 0) {
          this.ruleForm.manageGroupFullName = ''
          this.$emit('save')
        }
      }
    },
    // 设置管理员保存
    peopleBtn(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.peopleSubmit()
        } else {
          return false
        }
      })
    },
    changeEvent(val) {
      //角色
      if (val == 3) {
        this.flagwithe = false
      } else {
        this.flagwithe = true
      }
    },
    // 角色列表
    async roleData() {
      let param = {
        currentPage: 1,
        pageSize: 999,
        orderColume: 'lastUpdateTime',
        orderRule: 'DESC',
        name: ''
      }
      const res = await this.$axios.post(
        '/httpServe/role/getDataInfo',
        param,
        true
      )
      this.dataRoleList = res.data.content
      
      if (this.findIdByName(res.data.content, "安全管理员") == null) {
        this.ruleForm.userRole = res.data.content[0].id;
      }else{
        this.ruleForm.userRole = this.findIdByName(res.data.content, "安全管理员").id;
      }
    },
    findIdByName(array, name) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].name === name) {
          return array[i];
        }
      }
      return null; // 如果没有找到，返回 null
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item--small.el-form-item {
    margin-bottom: 17px;
}
</style>
