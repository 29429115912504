<template>
  <div>
    <div class="leftTable" style="width: 500px; height: 800px; margin-top: 25px; margin-left: 60px">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm" size="small">
        <el-form-item :label="$t('UserManage.user.addList.username')" prop="loginName">
          <el-input style="width: 250px" :disabled="disabled" v-model="ruleForm.loginName"></el-input>
        </el-form-item>
        <el-form-item :label="$t('UserManage.user.addList.name')" prop="userName">
          <el-input style="width: 250px" v-model="ruleForm.userName"></el-input>
        </el-form-item>
        <el-form-item :label="$t('UserManage.user.addList.telephone')" prop="phone">
          <el-input style="width: 250px" v-model="ruleForm.phone"></el-input>
        </el-form-item>
        <el-form-item :label="$t('UserManage.user.addList.mailbox')" prop="email">
          <el-input style="width: 250px" v-model="ruleForm.email"></el-input>
        </el-form-item>  
        <el-form-item :label="$t('UserManage.user.addList.sex')" prop="userSex">
          <el-select v-model="ruleForm.userSex" size="small" clear="select" style="width: 250px">
          <el-option v-for="item in sexOptions" :key="item.value" :label="$t(`UserManage.adminPage.${item.label}`)" :value="item.value">
          </el-option>
        </el-select>
        </el-form-item>
        <el-form-item :label="$t('UserManage.user.addList.cipher')" v-show="echo">
          <el-radio-group v-model="radio">
            <!-- this.$utils.common.encrypt(this.form.pass,"SM4",secretKey) -->
            <el-radio :label="1" @change="pasWordChange(1)">{{$t('UserManage.user.addList.DefaultPassword')}}</el-radio>
            <el-radio :label="2" @change="pasWordChange(2)">{{$t('UserManage.user.addList.Custom')}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="miMa" v-if="flag">
          <!-- <el-form style="height: 90px; margin-top: 10px" size="small" :model="form" :rules="rules1" ref="form" label-width="100px" class="demo-ruleForm restForm"> -->
          <el-form-item :label="$t('UserManage.user.addList.NewPassword')" prop="newPassword1">
            <el-input style="width: 250px" type="password" v-model="ruleForm.newPassword1" clearable ></el-input>
          </el-form-item>
          <el-form-item style="margin-top: 5px" :label="$t('UserManage.user.addList.ConfirmPassword')" prop="newPassword2">
            <el-input style="width: 250px" v-model="ruleForm.newPassword2" clearable type="password" ></el-input>
          </el-form-item>
          <!-- </el-form> -->
        </div>
        <el-form-item :label="$t('UserManage.user.addList.note')" v-if="xianShi">
          <span style="color: #bc0404">{{$t('UserManage.user.addList.TheDefaultPasswordIs')}}{{defaultPassword}}</span>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">
            {{$t('public.Save')}}
          </el-button>
        </el-form-item>
      </el-form>
    </div>

  </div>
</template>

<script>
import utils from '../../../../../src/utils/index.js'
export default {
  props: {
    addArray: {
      type: Object,
      default: () => {}
    },
    addArrayId: {
      type: String,
      default: ''
    }
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      undefined
      if (value === '') {
        undefined

        callback(new Error('请输入电话'))
      } else {
        undefined

        if (!/^[0-9]*$/.test(value)) {
          undefined

          callback(new Error('电话必须是正确的手机（电话）号码'))
        } else {
          undefined

          callback()
        }
      }
    }
    let validcodeName = (rule, value, callback) => {
      // ^\a-\z\A-\Z0-9\u4E00-\u9FA5
      // ^<>.,?;:'()!~%-_@#%/*""\s]+$"不允许输入特殊符号和字符
      let reg = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/
      if (!reg.test(value)) {
        callback(new Error('不支持空格及特殊字符'))
      } else {
        callback()
      }
    }
    return {
      //性别数据组
      sexOptions: [
        { value: '0', label: '男' },
        { value: '1', label: '女' }
      ],
      xianShi:true,
      echo: true,
      defaultPassword: '',
      radio: 1, //默认   默认密码
      flag: false, //默认不显示自定义密码框
      id: '', //左侧的id
      IDS: '', //编辑的row中的id
      disabled: false, //新增 FALSE 编辑true
      visibaelFlag: false, //提示弹框默认不显示
      titleInfo: '', //消息的字体
     
      ruleForm: {
        loginName: '', //用户名
        userName: '', //姓名
        phone: '', //电话
        email: '', //邮箱
        userSex: '0', //性别，默认男
        action: '', //新增传参
        groupId: '', //点击树的groupid
        userRole: '',
        filesName: '',
        //重置密码
        // oldPassword: "",
        newPassword1: '',
        newPassword2: ''
      },
      rules: {
        loginName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },

          {
            pattern: /^[0-9a-zA-Z_]{1,}$/,
            message: '用户名只支持英文（大小写）、数字或下划线组成',
            trigger: 'blur'
          },
          {
            min: 4,
            max: 32,
            message: '长度在 4 到 32 个字符',
            trigger: 'blur'
          }
        ],
        userName: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { validator: validcodeName, trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入电话' },
          { validator: validatePhone, trigger: 'blur' },
          {
            min: 1,
            max: 12,
            message: '最长输入12个字符，仅支持数字',
            trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          {
            type: 'email',
            message: '请输入正确的邮箱',
            trigger: ['blur', 'change']
          }
        ],
        // 密码
        newPassword1: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 8,
            max: 32,
            message: '8~32位，至少包含数字、字母、符号两种元素。',
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (
                 /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)/.test(value) ==
                false
              ) {
                callback(
                  new Error('8~32位，至少包含数字、字母、符号两种元素。')
                )
              } else {
                callback()
              }
            },
            trigger: 'blur'
            // trigger: 'change' //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
          }
        ],
        newPassword2: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          {
            min: 8,
            max: 32,
            message: '8~32位，至少包含数字、字母、符号两种元素。',
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请输入确认密码'))
              } else if (
                 /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)/.test(value) ==
                false
              ) {
                callback(
                  new Error('8~32位，至少包含数字、字母、符号两种元素。')
                )
              } else if (value !== this.ruleForm.newPassword1) {
                callback(new Error('两次输入密码不一致!'))
              }
              // else if (value === this.form.oldPassword) {
              //   callback(new Error('新密码不能与原密码相同!'))
              // }
              else {
                callback()
              }
            },
            trigger: 'blur' //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
          }
        ]
      }
    }
  },
  created() {
    this.searchPasWord() 
    // this.ruleForm=this.addArray
    if (this.addArray) {
      this.ruleForm = this.addArray 
      this.IDS = this.addArray.id
      if (this.IDS) {
        this.disabled = true
        this.echo = false
        this.xianShi = false
        this.$set(this.ruleForm, 'loginName', this.addArray.loginName)
        this.$set(this.ruleForm, 'userName', this.addArray.userName)
        this.$set(this.ruleForm, 'phone', this.addArray.phone)
        this.$set(this.ruleForm, 'email', this.addArray.email)
        this.$set(this.ruleForm, 'userSex', this.addArray.userSex + '')
        this.$set(this.ruleForm, 'id', this.addArray.id)
      } else {
        this.$set(this.ruleForm, 'userSex', "0")
        this.disabled = false
        this.echo = true
        this.xianShi = true
      }
     
    }
  },
  
  methods: {
    // 查询接口
    async searchPasWord() {
      var param = {}
      // console.log(data);
      const res = await this.$axios.post(
        'httpServe/security/config/selectDefaultPass',
        param,
        true
      )
      console.log(res, '查询默认密码')
      // utils.common.encrypt(this.ruleForm.pass,"SM4",secretKey)
      this.defaultPassword = utils.common.decrypt(res.data, '')
    },
    pasWordChange(val) {
      console.log(val, '------')
      if (val == 1) { 
        this.flag = false
        this.xianShi = true
      } else if (val == 2) {
        this.flag = true
        this.xianShi = false
      }
    },
    // 新增接口
    async addPost() {
      if (this.IDS) {
        var userSex = this.ruleForm.userSex
        var sex = Number(this.ruleForm.userSex)
        var param = {
          id: this.IDS + '',
          userName: this.ruleForm.userName, //姓名
          loginName: this.ruleForm.loginName, //用户名
          userSex: sex, //性别，默认男
          phone: this.ruleForm.phone, //电话
          email: this.ruleForm.email, //邮箱
          userRole: 4,
          useDeviceNum: 0,
          userStatus: 0,
          groupId: this.addArrayId,
          sendType: 0,
          status: 0,
          deviceCount: 0
        }
        const res = await this.$axios.post(
          '/httpServe/userInfo/update',
          param,
          true
        )
        if (res.data == 0) {
          this.ruleForm = {}
          this.$emit('addButton', 3)
        }
        // this.ruleForm = {};
        // this.$emit("addButton");
      } else { 
        if(this.radio == 1){
          this.ruleForm.newPassword2 = this.defaultPassword
        }
        var param = {
          loginName: this.ruleForm.loginName, //用户名
          userName: this.ruleForm.userName, //姓名
          phone: this.ruleForm.phone, //电话
          email: this.ruleForm.email, //邮箱
          userSex: this.ruleForm.userSex, //性别，默认男
          groupId: this.addArrayId,
          userRole: 4,
          // pass: this.ruleForm.newPassword2
          // this.$utils.common.encrypt(this.form.pass,"SM4",secretKey)
          pass: utils.common.encrypt(
          this.ruleForm.newPassword2,
          'SM4',
          $.getSecretKey(this.ruleForm.loginName)
        ),
             
          // newPass: utils.common.encrypt(
          //   this.ruleForm.newPassword2,
          //   'SM4',
          //   $.getSecretKey(this.ruleForm.loginName)
          // )
        }
        console.log(param, '----000保存按钮')
        const res = await this.$axios.post(
          '/httpServe/userInfo/insert',
          param,
          true
        )
        if (res.data == -1) {
          this.$message({
            message:this.$t('UserManage.user.addList.saveRepeat'),
            type: 'error',
            offset: 100
          })
          return false
        } else if (res.data == -2) {
          this.$emit('addButton', 2)
        } else if (res.data == 0) {
          this.ruleForm = {}
          this.$emit('addButton', 0)
        }
      }
    },
    // 新增保存
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit!");
          this.addPost()
        } else {
          return false
        }
      })
    },
    //弹框点击关闭事件
    handleClose() {
      this.visibaelFlag = false //提示弹框关闭
    },
    //确定的关闭
    determine() {
      this.visibaelFlag = false //提示弹框
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item--small.el-form-item {
    margin-bottom: 17px;
}
</style>
